body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
    url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
